import React, { useState, useEffect } from 'react';
import {Tag, Avatar} from 'antd'
import {Steps, Divider, Tabs} from 'antd-mobile'
import { QuestionCircleOutline } from 'antd-mobile-icons';
import { Dialog } from 'antd-mobile/es/components/dialog/dialog';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatTimeHHMMA } from '../../util/Helpers';
import leaf from "../../static/images/leaf.svg";
const { Step } = Steps;

const Created = ({orderInfo, scheduleSelector, info, customerInfo, postalCodeValid, themeMode}) => {
  const intl = useIntl()
  const options = { weekday: 'long', month: 'long', day: 'numeric' };
  const localeOption = intl.locale + '-' + intl.locale.toUpperCase()

  const getHeader = () => {
    console.log(JSON.stringify(orderInfo.tracking))
    if (orderInfo.tracking.completeAfter != null && orderInfo.tracking.completeBefore != null) {
      return (
        <>
          <div className="headerHeader">
            <FormattedMessage id="app.estimateDeliveryWindow"/>
          </div>
          <h3 className="headerText">
            {new Date(orderInfo.tracking.completeAfter).toLocaleDateString(localeOption, options)} {formatTimeHHMMA(new Date(orderInfo.tracking.completeAfter), intl.locale)} - {formatTimeHHMMA(new Date(orderInfo.tracking.completeBefore), intl.locale)}
          </h3>
        </>
      )
    }
    else if (orderInfo.tracking.completeAfter == null && orderInfo.tracking.completeBefore == null && orderInfo?.tracking?.rateChoice?.service != null && orderInfo.tracking.rateChoice.service === 'SCHEDULED') {
      // alert(scheduleSelector)
      return (
        <>
          {scheduleSelector()}
        </>
      )
    }
    else return ''
  }

  useEffect(() => {
    if (themeMode) {
      document.documentElement.style.setProperty('--background-color', '#000d1a')
    }
    else if (!themeMode){
      document.documentElement.style.setProperty('--background-color', '#FFFFFF')
    }
  }, [themeMode])

  const buildTimeline = (timeline) => {
    let reverseTimeline = [].concat(timeline).reverse()
    const customIcon = (i) => (
      <svg className="stepIcon" width="20" height="20" viewBox="0 0 44 44">
        <circle cx="22"
                cy="22"
                r="20"
                stroke="white"
                stroke-width="2"
                fill="#1890ff"/>
        <text font-size="20"
              fill="white"
              font-family="Verdana"
              text-anchor="middle"
              alignment-baseline="middle"
              x="22"
              y="22">{i}</text>
      </svg>
    );
    const openDialog = () => {
      let content = <span style={{color: themeMode?"#FFFFFF":''}}>{orderInfo.tracking.merchantName}{intl.formatMessage({id: "app.dialog.content.created"})}</span>
      let title = <span style={{color: themeMode?"#FFFFFF":''}}>{intl.formatMessage({id: "app.dialog.title.created"})}</span>
      let actions = [[
        {
          key: 'cancel',
          text: intl.formatMessage({id: "app.close"}),
          style: {backgroundColor: themeMode ? "#000d1a": ""}
        }
      ]]
      return Dialog.show({content: content, title: title, actions: actions, closeOnAction: true, bodyStyle: {backgroundColor: themeMode ? "#000d1a": ""}})
    }

    const chooseDialog = (x, index) => {
      if (index === 0 && x.eventName !== 'windowChange') {
        return (<> <QuestionCircleOutline onClick={openDialog} /></>)
      }
      else if (x.eventName === 'windowChange' && x.externalNotes != null) {
        
        const openWindowChangeDialog = () => {
          let content = <span style={{color: themeMode?"#FFFFFF":''}}>{x.externalNotes}</span>
          let title = <span style={{color: themeMode?"#FFFFFF":''}}>{intl.formatMessage({id: "app.dialog.title.windowChange"})}</span>
          let actions = [
            {
              id: 'dialogFooter',
              key: 'cancel',
              text: intl.formatMessage({id: "app.close"}),
              style: {backgroundColor: themeMode ? "#000d1a": ""},
              className: 'dialogFooterDark'
            }
            //style: {backgroundColor: themeMode? '#000d1a': ''}
          ]
          return Dialog.show({
            id: 'dialogFooter', 
            content: content, 
            title: title, 
            actions: actions, 
            closeOnAction: true, 
            bodyStyle: {
              backgroundColor: themeMode ? "#000d1a": ""
            }
          })
        }
        return (<> <QuestionCircleOutline onClick={openWindowChangeDialog} /></>)
      }
      else return ''
    }
    return (
      <div className="stepsContainer">
        {/* <Tabs style={{fontSize: 12}}>
          <Tabs.TabPane title={<span style={{fontSize: 12}}>Info</span>}key='1'>
            {info()}
          </Tabs.TabPane>
          <Tabs.TabPane title={<span style={{fontSize: 12}}>{intl.formatMessage({id: "app.statusUpdates"})}</span>} key='2'>
            <div className="steps">
              <Steps size="small" direction="vertical">
                {reverseTimeline.map((e,i) => {return (
                  <Step
                    status='finish'
                    title={<span className="stepTitle"><strong>{e[intl.locale]}</strong> {(i === 0? <QuestionCircleOutline onClick={openDialog}/>:'')}</span>}
                    icon={customIcon(reverseTimeline.length - i)}
                    description={<span className="stepDescription">{e.location? `${e.location.charAt(0).toUpperCase() + e.location.slice(1)}, `:''}{new Date(e.timestamp).toLocaleDateString(localeOption, options)} {formatTimeHHMMA(new Date(e.timestamp), intl.locale)}</span>}
                    key={i}
                  >
                  </Step>
                )})}
              </Steps>
            </div>
          </Tabs.TabPane>
          {postalCodeValid &&
          <Tabs.TabPane title={<span style={{fontSize: 12}}>{intl.formatMessage({id: "app.customerInformation"})}</span>} key='3'>
            {customerInfo()}
          </Tabs.TabPane>}
        </Tabs> */}
        <div className="statusUpdates"><FormattedMessage id="app.statusUpdates"/></div>
        <div className="steps">
          <Steps size="small" direction="vertical">
            {reverseTimeline.map((e,i) => {return (
              <Step
                status='finish'
                title={<span className="stepTitle"><strong>{e[intl.locale]}</strong>{chooseDialog(e,i)}</span>}
                icon={customIcon(reverseTimeline.length - i)}
                description={<span className="stepDescription">{e.location? `${e.location.charAt(0).toUpperCase() + e.location.slice(1)}, `:''}{new Date(e.timestamp).toLocaleDateString(localeOption, options)} {formatTimeHHMMA(new Date(e.timestamp), intl.locale)}</span>}
                key={i}
              >
              </Step>
            )})}
          </Steps>
          {/* <Dialog
            content='hello'
            visible={true}
            actions={[
              {
                id: 'dialogFooter',
                key: 'cancel',
                text: <span style={{color: themeMode?"#FFFFFF":''}}>{intl.formatMessage({id: "app.close"})}</span>,
                style: {backgroundColor: themeMode? '#000d1a': ''},
                className: 'dialogFooterDark'
              }
            ]}
          >

          </Dialog> */}
        </div>
        
      </div>
    )
  }

  return (
    <div className="orderInfoSubcomponentContainer">
      <div>
        <div className="merchantHeaderContainer">
          {orderInfo.tracking.logoUrl && <Avatar className="merchantHeaderIcon" src={orderInfo.tracking.logoUrl}/>} {orderInfo.tracking.merchantName && <span className="merchantHeaderName" style={{paddingLeft: orderInfo.tracking.logoUrl == null? '10px': ''}}>{orderInfo.tracking.merchantName}</span>}
          <span className="merchantHeaderTagContainer">
            <Tag color="blue" className="tag"><FormattedMessage id="app.status.created"/></Tag>
          </span>
          
        </div>
        
        <div className="merchantHeaderContainer">
          <span className="carbonNeutralHeader"><img key={"1"} src={leaf}/> {intl.formatMessage({id: "app.carbonNeutral"})} <a style={{textDecoration: 'underline', color: '#1890ff'}} href="https://blog.boxknight.com/a-carbon-neutral-boxknight">{intl.formatMessage({id: "app.learnMore"})}{' ->'}</a></span>
        </div>

        <div className="merchantHeaderDivider">
          <Divider className="merchantHeaderDivider"/>
        </div>
        
        <div className="bodyContainer">
          {getHeader(orderInfo.tracking.timeline)}
          {buildTimeline(orderInfo.tracking.timeline)}
          {/* {confirmPostalCodeContent()} */}
        </div>
      </div>
    </div>
  );
}
export default Created
