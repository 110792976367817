import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { Button, Row, Steps, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
const { Step } = Steps;

const Wizard = ({ initialValues={}, onSubmit, children }) => {
  // static propTypes = {
  //   onSubmit: PropTypes.func.isRequired
  // };
  // static Page = ({ children }) => children;

  const [page, setPage] = useState(0)
  const [values, setValues] = useState(initialValues)

  const next = values => {
    setPage((page)=> Math.min(page+1, children.length - 1))
    setValues(values);
    // this.setState(state => ({
    //   page: Math.min(state.page + 1, this.props.children.length - 1),
    //   values
    // }));

  }

  const previous = () =>
    setPage((page)=>Math.max(page - 1, 0))

  const setNewValues = (newValues) =>
    setValues(newValues)

  const validate = values => {
    const activePage = React.Children.toArray(children)[
      page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  const handleSubmit = values => {
    const isLastPage = page === React.Children.count(children) - 1;
    next(values);
  };

  const generateButtons = (page, isLastPage, submitting) =>
    <div className="buttons">
      <Row gutter={16} type={"flex"} justify={"start"} align={"middle"}>
        {page > 0 && (
          <Col>
            <Button onClick={previous}><ArrowLeftOutlined color="#1890ff"/></Button>
          </Col>
        )}
      </Row>
    </div>

  // const { children } = this.props;
  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {...child.props, values: values, setValues: setNewValues})
  );
  // const { page, values } = this.state;
  const activePage = React.Children.toArray(childrenWithProps)[page];
  const isLastPage = page === React.Children.count(children) - 1;
  return (
    <div>
      <Form
        initialValues={values}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            {/* {generateButtons(page, isLastPage, submitting)} */}
            <div className={"form-step-row"}>
              {activePage}
            </div>
          </form>
        )}
      </Form>
    </div>
  );
  
}

export const Page = ({ children }) => children;

Wizard.propTypes= {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.array
}
export default Wizard;
