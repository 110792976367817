import React, { useRef, useState } from 'react';
import {Tag, Avatar} from 'antd'
import {Steps, Divider, Button, ActionSheet, Tabs} from 'antd-mobile'
import { QuestionCircleOutline, CheckCircleOutline, LocationOutline, MessageOutline} from 'antd-mobile-icons';
import { Dialog } from 'antd-mobile/es/components/dialog/dialog';
import moment from 'moment-timezone';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatTimeHHMMA } from '../../util/Helpers';
import leaf from "../../static/images/leaf.svg";
const { Step } = Steps;

const Active = ({orderInfo, confirmPostalCodeContent, scheduleSelector, info, customerInfo, postalCodeValid, themeMode}) => {
  const intl = useIntl()
  const options = { weekday: 'long', month: 'long', day: 'numeric' };
  const localeOption = intl.locale + '-' + intl.locale.toUpperCase()

  const [showContactActionSheet, setShowContactActionSheet] = useState(false)
  const actions = [
    { text: intl.formatMessage({id: "app.callYourDriver"}), key: 'callYourDriver' },
    { text: intl.formatMessage({id: "app.sendAMessage"}), key: 'sendAMessage'}
  ]

  const getHeader = () => {
    if (orderInfo.tracking.eta != null) {
      const myDate = new Date(orderInfo.tracking.eta)

      const isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
          someDate.getMonth() == today.getMonth() &&
          someDate.getFullYear() == today.getFullYear()
      }

      let isETAToday = isToday(myDate)
      let finalString = moment(orderInfo.tracking.eta).format('MMM D h:mm A')
      if (isETAToday) finalString = 'Today ' + moment(orderInfo.tracking.eta).format('h:mm A')
      if (intl.locale === 'fr') {
        finalString = moment(orderInfo.tracking.eta).format('MMM D h:mm')
        if (isETAToday) finalString = "Aujourd'hui " + moment(orderInfo.tracking.eta).format('h:mm')
      }
      
      return (
        <>
          <div className="headerHeader">
            <FormattedMessage id="app.eta" />
          </div>
          <h3 className="headerText">
            {finalString}
          </h3>
        </>
      )
    }
    else if (orderInfo.tracking.completeAfter != null && orderInfo.tracking.completeBefore != null) {
      return (
        <>
          <div className="headerHeader">
            <FormattedMessage id="app.estimateDeliveryWindow"/>
          </div>
          <h3 className="headerText">
            {new Date(orderInfo.tracking.completeAfter).toLocaleDateString(localeOption, options)} {formatTimeHHMMA(new Date(orderInfo.tracking.completeAfter), intl.locale)} - {formatTimeHHMMA(new Date(orderInfo.tracking.completeBefore), intl.locale)}
          </h3>
        </>
      )
    }
    else if (orderInfo?.tracking?.rateChoice?.service != null && orderInfo.tracking.rateChoice.service === 'SCHEDULED') {
      // alert(scheduleSelector)
      return (
        <>
          {scheduleSelector()}
        </>
      )
    }
    else return ''
  }

  const buildTimeline = (timeline) => {
    let reverseTimeline = [].concat(timeline).reverse()
    const customIcon = (i, actualIndex) => (
      <svg className="stepIcon" width="20" height="20" viewBox="0 0 44 44">
        <circle cx="22"
                cy="22"
                r="20"
                stroke="white"
                stroke-width="2"
                fill={actualIndex===0? "#00D1D1":"#1890ff"}
        />
        <text font-size="20"
              fill="white"
              font-family="Verdana"
              text-anchor="middle"
              alignment-baseline="middle"
              x="22"
              y="22">{i}</text>
      </svg>
    );

    const getStepDescription = (e) => {
      if (e.eventName === 'youreNext') {
        if (e.frDescription != null && e.enDescription != null) {
          return intl.locale === 'fr'? <span className="stepDescription">{e.frDescription}</span> : <span className="stepDescription">{e.enDescription}</span> 
        } 
        else return ''
      }
      else{
        return (
          <span className="stepDescription">
            {e.location? `${e.location.charAt(0).toUpperCase() + e.location.slice(1)}, `:''}{new Date(e.timestamp).toLocaleDateString(localeOption, options)} {formatTimeHHMMA(new Date(e.timestamp), intl.locale)}
          </span>
        )
      }
    }
    const getStepTitle = (e, i) => {
      if (e.workerName != null && e.enSubtitle != null&& e.frSubtitle != null) {
        return (
          <span className="stepTitle" style={{lineHeight:'1.5em'}}>
            <div>
              <strong style={{color:i===0?'#00D1D1':''}}>{e[intl.locale]}</strong>
            </div>
            {e.workerName && e.enSubtitle && e.frSubtitle && 
            <div>
              <strong>{e.workerName}</strong>{intl.locale==='fr'? e.frSubtitle : e.enSubtitle}
            </div>}
          </span>
        )
      }
      else {
        return (
          <span className="stepTitle" style={{color:i===0?'#00D1D1':''}}>
            <strong>{e[intl.locale]}</strong>
          </span>
        )
      }
    }

    const chooseDialog = (x, index) => {
      if (index === 0 && x.eventName !== 'windowChange') {
        return ''
      }
      else if (x.eventName === 'windowChange' && x.externalNotes != null) {
        const openWindowChangeDialog = () => {
          let content = <span style={{color: themeMode?"#FFFFFF":''}}>{x.externalNotes}</span>
          let title = <span style={{color: themeMode?"#FFFFFF":''}}>{intl.formatMessage({id: "app.dialog.title.windowChange"})}</span>
          let actions = [[
            {
              key: 'cancel',
              text: intl.formatMessage({id: "app.close"}),
              style: {backgroundColor: themeMode ? "#000d1a": ""}
            }
          ]]
          return Dialog.show({content: content, title: title, actions: actions, closeOnAction: true, bodyStyle: {backgroundColor: themeMode ? "#000d1a": ""}})
        }
        return (<> <QuestionCircleOutline onClick={openWindowChangeDialog} /></>)
      }
      else return ''
    }

    return (
      // <div className="stepsContainer">
      //   <Tabs style={{fontSize: 12}}>
      //     <Tabs.TabPane title={<span style={{fontSize: 12}}>Info</span>}key='1'>
      //       {info()}
      //     </Tabs.TabPane>
      //     <Tabs.TabPane title={<span style={{fontSize: 12}}>{intl.formatMessage({id: "app.statusUpdates"})}</span>} key='2'>
      //       <div className="steps">
      //         <Steps size="small" direction="vertical">
      //           {reverseTimeline.map((e,i) => {return (
      //             <Step
      //               status='finish'
      //               title={getStepTitle(e, i)}
      //               icon={customIcon(reverseTimeline.length - i, i)}
      //               description={getStepDescription(e)}
      //               key={i}
      //             >
      //             </Step>
      //           )})}
      //         </Steps>
      //       </div>
      //     </Tabs.TabPane>
      //     {postalCodeValid &&
      //     <Tabs.TabPane title={<span style={{fontSize: 12}}>{intl.formatMessage({id: "app.customerInformation"})}</span>} key='3'>
      //       {customerInfo()}
      //     </Tabs.TabPane>}
      //   </Tabs>
      // </div>
      <div className="stepsContainer">
        <div className="statusUpdates"><FormattedMessage id="app.statusUpdates"/></div>
        <div className="steps">
          <Steps size="small" direction="vertical">
            {reverseTimeline.map((e,i) => {return (
              <Step
                status='finish'
                title={<>{getStepTitle(e, i)}{chooseDialog(e,i)}</>}
                icon={customIcon(reverseTimeline.length - i, i)}
                description={getStepDescription(e)}
                key={i}
              >
              </Step>
            )})}
          </Steps>
        </div>
        
      </div>
    )
  }

  return (
    <div className="orderInfoSubcomponentContainer">
      <div>
        <div className="merchantHeaderContainer">
          {orderInfo.tracking.logoUrl && <Avatar className="merchantHeaderIcon" src={orderInfo.tracking.logoUrl}/>} {orderInfo.tracking.merchantName && <span className="merchantHeaderName" style={{paddingLeft: orderInfo.tracking.logoUrl == null? '10px': ''}}>{orderInfo.tracking.merchantName}</span>}
          <span className="merchantHeaderTagContainer">
            <Tag color="cyan" style={{fontWeight: 'bold'}}><FormattedMessage id="app.status.active"/></Tag>
          </span>
          
        </div>

        <div className="merchantHeaderContainer">
          <span className="carbonNeutralHeader"><img key={"1"} src={leaf}/> {intl.formatMessage({id: "app.carbonNeutral"})} <a style={{textDecoration: 'underline', color: '#1890ff'}} href="https://blog.boxknight.com/a-carbon-neutral-boxknight">{intl.formatMessage({id: "app.learnMore"})}{' ->'}</a></span>
        </div>

        <div className="merchantHeaderDivider">
          <Divider className="merchantHeaderDivider"/>
        </div>
        
        <div className="bodyContainer">
          {getHeader(orderInfo.tracking.timeline)}
          {buildTimeline(orderInfo.tracking.timeline)}
        </div>
        <div className="activeButtonContainer">
          {confirmPostalCodeContent()}
        </div>
        <div className="activeButtonContainer">
          <Button
            block
            onClick={() => setShowContactActionSheet(true)}
            color="primary"
          >
            <MessageOutline/> <FormattedMessage id="app.contactYourDriver"/>
          </Button>
        </div>
      </div>
      <ActionSheet
        visible={showContactActionSheet}
        actions={actions}
        onClose={() => setShowContactActionSheet(false)}
        extra={intl.formatMessage({id:'app.driverWontSeePhoneNumber'})}
        cancelText={intl.formatMessage({id: "app.cancel"})}
        onAction={action => {
          if (action.key === 'sendAMessage') {
            let hyperlink = document.createElement("a");
            hyperlink.setAttribute("href", "sms://66358");
            document.body.appendChild(hyperlink);
            hyperlink.click();
            hyperlink.parentNode.removeChild(hyperlink)
          }
          else if (action.key === 'callYourDriver'){
            let hyperlink = document.createElement("a");
            hyperlink.setAttribute("href", "tel://66358");
            document.body.appendChild(hyperlink);
            hyperlink.click();
            hyperlink.parentNode.removeChild(hyperlink)
          }
        }}
      />
    </div>
  );
}
export default Active