import logo from './logo.svg';
import "antd/dist/antd.css";
import './App.css';
import { Layout, Menu, Breadcrumb } from 'antd';
import Routes from "./router/Routes";

// import 'antd-mobile/dist/antd-mobile.css';
import {Import} from "./Import";
import React, { useState } from "react";
import { Route, Switch, withRouter, BrowserRouter } from "react-router-dom";
import Div100vh from 'react-div-100vh'
import {IntlProvider} from "react-intl";

import locale_en from "./translations/en.json";
import locale_fr from "./translations/fr.json";
import { useLocalStorage } from './hooks/useLocalStorage';
import { useLocation } from 'react-router';
import queryString from "query-string"
import {Helmet} from "react-helmet";

const { Header, Content, Footer, Sider } = Layout;

const DynamicRoute = ({path, component, onChangeLanguage, locale}) => {
  const modifyAddressFlag = false
  const modifyScheduleFlag = false
  return (
    <Import
      touch={() => import(`./components/touch/${component}`)}
      desktop={() => import(`./components/desktop/${component}`)}
    >
      {Comp =><Route path={path} render={props => <Comp {...props} onChangeLanguage={onChangeLanguage} locale={locale} modifyAddressFlag={modifyAddressFlag} modifyScheduleFlag={modifyScheduleFlag}/>}/>}
    </Import> 
  )
}



const App = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const [locale, setLocale] = useLocalStorage('lang', 'en')
  let myLocale = params.locale === 'en' || params.locale === 'fr'? params.locale : null
  if (myLocale && (locale !== myLocale)) {
    setLocale(myLocale)
  }
  
  const data = {
    'fr': locale_fr,
    'en': locale_en
  };

  const onChangeLanguage = (event) => {
    let lang = event;
    switch (lang) {
      case "en":
        setLocale("en")
        break;
      case "fr":
        setLocale("fr")
        break;
      default:
        setLocale("en")
        break;
    }
  }

  return (
    <IntlProvider key={locale} locale={locale} messages={data[locale]}>
      <Helmet>
        <meta name="theme-color" content="#FFFFF" />
      </Helmet>
      <Layout className="App">
          <Content className={"app-content"}>
              <Div100vh className={"container"}>
                  <BrowserRouter>
                    <Switch>
                      <DynamicRoute path="/tracking/:trackingNo?" component="OrderInfo" onChangeLanguage={onChangeLanguage} locale={locale}/>
                      <DynamicRoute path="/" component="EnterTracking" onChangeLanguage={onChangeLanguage} locale={locale}/>
                    </Switch>
                  </BrowserRouter>
              </Div100vh>
          </Content>
      </Layout>
      </IntlProvider>
  );
}

export default App;
