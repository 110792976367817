import React, { useEffect, useState, useRef } from 'react';
import {Input, AutoComplete} from 'antd'
import {Icon, NavBar, Card, Steps, Button, Toast, Space, Dialog, FloatingPanel, ActionSheet, Picker, Loading, Form, Radio, TextArea} from 'antd-mobile'
import { CheckCircleOutline, CloseOutline, EditSOutline } from 'antd-mobile-icons';
import MapComponent from '../MapComponent';
import { FormattedMessage, injectIntl, intlShape, useIntl } from 'react-intl';
import moment from 'moment';
import 'rmc-picker/assets/index.css';
import 'rmc-date-picker/assets/index.css';
import 'rmc-picker/assets/popup.css';
import DatePicker from 'rmc-date-picker'
import PopPicker from 'rmc-date-picker/lib/Popup'
import { modifyAddress, modifyOrder, reschedule } from '../../util/APIUtils';
import { setSyntheticTrailingComments } from 'typescript';
const autocomplete = new window.google.maps.places.AutocompleteService();
const geocoder = new window.google.maps.Geocoder();

const DetailedView = ({renderSwitch, recipientAddress, orderInfo, windowHeight, handleOrderInfoUpdate, postalCode, handleRecipientAddressUpdate, handlePostalCodeUpdate, footer, modifyAddressFlag, modifyScheduleFlag, themeMode}) => {


  // const modifyScheduleFlag = false
  // const modifyAddressFlag = false

  const [form] = Form.useForm();
  const dialogRef = useRef(null)
  const intl = useIntl()
  const options = { weekday: 'long', month: 'long', day: 'numeric' };
  const localeOption = intl.locale + '-' + intl.locale.toUpperCase()  
  const [actionSheetVisisble, setActionSheetVisible] = useState(false)
  const [windowPickerVisible, setWindowPickerVisible] = useState(false)
  const [confirmRescheduleVisible, setConfirmRescheduleVisible] = useState(false)
  const [newWindow, setNewWindow] = useState(null)
  const [loadingReschedule, setLoadingReschedule] = useState(false)
  
  const [datePickerVisible, setDatePickerVisible] = useState(false)
  const [newDate, setNewDate] = useState()

  const [modifyAddressFormVisible, setModifyAddressFormVisible] = useState(false)
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([])
  const [formValues, setFormValues] = useState(null)
  const [geocodedAddressInfo, setGeocodedAddressInfo] = useState(null)
  const [reviewModifyAddressVisible, setReviewModifyAddressVisible] = useState(false)
  const [loadingModifyAddress, setLoadingModifyAddress] = useState(false)
  const [finalIsBusinessAddress, setFinalIsBusinessAddress] = useState(recipientAddress.isBusinessAddress? recipientAddress.isBusinessAddress : false)
  
  const [addDeliveryInstructionsFormVisible, setAddDeliveryInstructionsFormVisible] = useState(false)
  const [loadingModifyOrder, setLoadingModifyOrder] = useState(false)
  
  const actions = [
    { text: intl.formatMessage({id: "app.addNotes"}), key: 'addDeliveryInstructions'}
  ]
  if (orderInfo.tracking.worker == null && modifyAddressFlag) actions.splice(0, 0, { text: intl.formatMessage({id: "app.modifyAddress"}), key: 'modifyAddress'})
  if (orderInfo.tracking.worker == null && modifyScheduleFlag) actions.splice(1, 0, { text: intl.formatMessage({id: "app.rescheduleDelivery"}), key: 'rescheduleDelivery'})
  
  const anchors = [windowHeight * 0.52, windowHeight * 0.8, windowHeight * 1.1]

  useEffect(() => {
  }, [themeMode])

  const basicColumns = [
    ['10:00 AM - 6:00 PM', '6:00 PM - 10:00 PM']
  ]

  const confirmWindow = (val) => {
    // alert(val)
    setNewWindow(val)
    setWindowPickerVisible(false)
    setConfirmRescheduleVisible(true)
  }

  const getTomorrow = () => {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    return tomorrow
  }
  const getFourteenDaysFromTomorrow = () => {
    const tomorrow = getTomorrow()
    const fourteenDays = new Date(tomorrow)
    fourteenDays.setDate(tomorrow.getDate() + 14)
    return fourteenDays
  }

  const changeWindow = () => {
    setLoadingReschedule(true)
    const myFromDateTime = new Date(newDate)
    myFromDateTime.setHours(0,0,0,0)
    myFromDateTime.setMinutes(0,0,0,0)

    const myToDateTime = new Date(newDate)
    myToDateTime.setHours(0,0,0,0)
    myToDateTime.setMinutes(0,0,0,0)
    if (newWindow.toString() === '10:00 AM - 6:00 PM') {
      myFromDateTime.setHours(10)
      myToDateTime.setHours(18)
    }
    else {
      myFromDateTime.setHours(18)
      myToDateTime.setHours(22)
    }
    const finalFromDateTime = moment(myFromDateTime).valueOf()
    const finalToDateTime = moment(myToDateTime).valueOf()
    const finalBody = {
      completeAfter: finalFromDateTime,
      completeBefore: finalToDateTime,
      category: 'TRACKING_PAGE_UPDATE',
      changedBy: 'trackingPageUser',
      postalCode: postalCode
    }
    reschedule(orderInfo.tracking.id, finalBody)
      .then(res => {
        Toast.show(intl.formatMessage({id: "app.rescheduleSuccess"}), 2)
        let newTimeline = orderInfo.tracking.timeline
        newTimeline.push({
          event: 'Delivery Window Change',
          timestamp: moment().valueOf(),
          en: "Delivery Window Change",
          fr: "Changement de fenêtre de livraison"
        })
        let newOrderInfo = {
          ...orderInfo,
          tracking: {
            ...orderInfo.tracking,
            completeAfter: finalFromDateTime,
            completeBefore: finalToDateTime,
            timeline: newTimeline
          }
        }
        handleOrderInfoUpdate(newOrderInfo)
        setConfirmRescheduleVisible(false)
        setLoadingReschedule(false)
      })
      .catch(error => {
        Toast.show(intl.formatMessage({id: "app.rescheduleFail"}), 2)
        setLoadingReschedule(false)
        setConfirmRescheduleVisible(false)
      })
  }

  const rescheduleContent = () => {
    return (
      <>
        <PopPicker 
          style={{color: "1890ff"}}
          title={intl.formatMessage({id:'app.selectDate'})}
          locale={intl.locale}
          visible={datePickerVisible}
          dismissText={intl.formatMessage({id:'app.cancel'})}
          okText={intl.formatMessage({id:'app.next'})}
          onDismiss={() => setDatePickerVisible(false)}
          onChange={(d) => {setNewDate(d); setDatePickerVisible(false); setWindowPickerVisible(true)}}
          mode={'date'}
          datePicker={
            <DatePicker
              style={{color: "1890ff"}}
              minDate={getTomorrow()}
              maxDate={getFourteenDaysFromTomorrow()}
            />
          }
        />
        
        <Picker
          title="Select Time"
          cancelText="Back"
          confirmText="Select"
          columns={basicColumns}
          visible={windowPickerVisible}
          onConfirm={val => confirmWindow(val)}
          onClose={() => {
            setWindowPickerVisible(false)
            setDatePickerVisible(false)
          }}
          onCancel={() => {
            setWindowPickerVisible(false)
            setDatePickerVisible(true)
          }}
          onSelect={val => {
            console.log('onSelect', val)
          }}
        >
        </Picker>

        {newDate &&
        <Dialog
          visible={confirmRescheduleVisible}
          title={intl.formatMessage({id: "app.review"})}
          content={
            <>
              <FormattedMessage id="app.reschedule"/>: {newDate.toLocaleDateString(localeOption, options)}, {newWindow}
              {loadingReschedule && <p><Loading /></p>}
            </>
          }
          onClose={() => {
            setWindowPickerVisible(true)
            setConfirmRescheduleVisible(false)
          }}
          actions={
            [
              [
                {
                  key: 'cancel',
                  text: <span className="cancelButton">{intl.formatMessage({id: "app.cancel"})}</span>,
                  onClick: () => {setDatePickerVisible(false) ; setWindowPickerVisible(true); setConfirmRescheduleVisible(false)},
                },
                {
                  key: 'confirm',
                  text: intl.formatMessage({id: "app.confirm"}),
                  onClick: () => changeWindow()
                }
              ]
            ]
          }
        />
        }
      </>
    )
  }

  const handleStreetChange = value => {
    // setStreet(value)
    autocomplete.getPlacePredictions(
      {
        input: value,
        componentRestrictions: { country: "ca" }
      },
      predictions => {
        if (predictions != null) {
          setAutoCompleteOptions(predictions.map(prediction => {return { value: prediction.description, text: prediction.description }}))
        }
      }
    );
  };

  const handleStreetAutocompleteSelect = (value, option) => {
    geocoder.geocode({ address: value }, (results, status) => {
      if ((status = "OK")) {
        let addressComponents = results[0]["address_components"];
        let number = addressComponents.filter(component => {
          return component.types.includes("street_number");
        })[0]["long_name"];
        let street = addressComponents.filter(component => {
          return component.types.includes("route");
        })[0]["long_name"];
        let city = addressComponents.filter(component => {
          return component.types.includes("locality");
        })[0]["long_name"];
        let province = addressComponents.filter(component => {
          return component.types.includes("administrative_area_level_1");
        })[0]["short_name"];
        let country = addressComponents.filter(component => {
          return component.types.includes("country");
        })[0]["long_name"];
        let aPostalCode = addressComponents.filter(component => {
          return component.types.includes("postal_code");
        })[0]["long_name"];

        setAutoCompleteOptions([{label:`${number} ${street}`, value: `${number} ${street}`}])
        form.setFieldsValue({ street: `${number} ${street}` });
        form.setFieldsValue({ unit: "" });
        form.setFieldsValue({ city: city });
        form.setFieldsValue({ province: province });
        form.setFieldsValue({ country: country });
        form.setFieldsValue({ myPostalCode: aPostalCode });
      }
    });
  };


  const handleSubmitAddress = (values) => {
    let finalValues = values
    // geocode again
    let value = values.street + " " + values.city + " " + values.province + " " + values.country + " " + values.province + " " + values.myPostalCode
    
    geocoder.geocode({ address: value }, (results, status) => {
      if ((status = "OK")) {
        let addressComponents = results[0]["address_components"];
        let number = addressComponents.filter(component => {
          return component.types.includes("street_number");
        })[0]["long_name"];
        let street = addressComponents.filter(component => {
          return component.types.includes("route");
        })[0]["long_name"];
        let city = addressComponents.filter(component => {
          return component.types.includes("locality");
        })[0]["long_name"];
        let province = addressComponents.filter(component => {
          return component.types.includes("administrative_area_level_1");
        })[0]["short_name"];
        let country = addressComponents.filter(component => {
          return component.types.includes("country");
        })[0]["long_name"];
        let aPostalCode = addressComponents.filter(component => {
          return component.types.includes("postal_code");
        })[0]["long_name"];
        
        finalValues = {
          number: number,
          street: `${number} ${street}`,
          // unit: values.unit,
          city: city,
          province: province,
          country: country,
          myPostalCode: aPostalCode,
          isBusinessAddress: values.isBusinessAddress
        }

        if (values.unit != null && values.unit != '') {
          finalValues.unit = values.unit
        }
        // alert(JSON.stringify(values))
        // if (values.isBusinessAddress != null && values.isBusinessAddress != '') {
        //   finalValues.isBusinessAddress = values.isBusinessAddress
        // }

        if (results[0]&& results[0].geometry && results[0].geometry.location && results[0].geometry.location.lat() && results[0].geometry.location.lng()) {
          setGeocodedAddressInfo({lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()})
        }

        setModifyAddressFormVisible(false)
        setFormValues(finalValues)
        setReviewModifyAddressVisible(true)

      }
    });
  }

  const modifyAddressContent = () => {
    return (
      <>
        <Dialog
          visible={modifyAddressFormVisible}
          title={intl.formatMessage({id: "app.modifyAddress"})}
          content={
            <Form
              style={{height: '50vh'}}
              form={form}
              footer={
                <>
                  <Button block type='submit' color='primary'>
                    {intl.formatMessage({id:"app.next"})}
                  </Button>
                  <Button block style={{marginTop: 5}} onClick={() => {setReviewModifyAddressVisible(false) ; setModifyAddressFormVisible(false); setFormValues(null); setGeocodedAddressInfo(null)}}>
                    {intl.formatMessage({id:"app.cancel"})}
                  </Button>
                </>
              }
              onFinish={values => handleSubmitAddress(values)}
            >
              <Form.Item
                name='street'
                label={intl.formatMessage({id: "app.street"})}
                rules={[{ required: true, message: intl.formatMessage({id: "app.required"}) }]}
              >
                <AutoComplete
                  options={autoCompleteOptions}
                  style={{width:'100%'}}
                  onChange={value => handleStreetChange(value)}
                  onSelect={handleStreetAutocompleteSelect}
                />
              </Form.Item>
              <Form.Item
                name='unit'
                label='Unit'
              >
                <Input onChange={console.log} placeholder='Unit'/>
              </Form.Item>
              <Form.Item
                name='city'
                label={intl.formatMessage({id: "app.city"})}
                rules={[{ required: true, message: intl.formatMessage({id: "app.required"}) }]}
              >
                <Input placeholder='City'/>
              </Form.Item>
              <Form.Item
                name='country'
                label={intl.formatMessage({id: "app.country"})}
                rules={[{ required: true, message: intl.formatMessage({id: "app.required"}) }]}
              >
                <Input onChange={console.log} placeholder='Country'/>
              </Form.Item>
              <Form.Item
                name='province'
                label='Province'
                rules={[{ required: true, message: intl.formatMessage({id: "app.required"}) }]}
              >
                <Input onChange={console.log} placeholder='Province'/>
              </Form.Item>
              <Form.Item
                name='myPostalCode'
                label={intl.formatMessage({id: "app.postalCode"})}
                rules={[{ required: true, message: intl.formatMessage({id: "app.required"}) }]}
              >
                <Input onChange={console.log} placeholder='Postal Code'/>
              </Form.Item>
              <Form.Item
                name='isBusinessAddress'
                initialValue={finalIsBusinessAddress}
              >
                {intl.formatMessage({id: "app.isBusinessAddress"})}
                <Radio.Group defaultValue={finalIsBusinessAddress} onChange={v => setFinalIsBusinessAddress(v)}>
                  <Space direction='vertical'>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Form>
          }
          onClose={() => {
            setModifyAddressFormVisible(false)
            setReviewModifyAddressVisible(true)
          }}
        />
        
        {formValues &&
        <div ref={dialogRef}>
        <Dialog 
          // ref={dialogRef}
          visible={reviewModifyAddressVisible}
          title={intl.formatMessage({id: "app.reviewModifyAddress"})}
          content={
            <>
              <div>
                <p style={{margin: 0}}>{formValues.street}{formValues.unit? ', unit: '+ formValues.unit: ''}</p>
                <p style={{margin: 0}}>{formValues.city}, {formValues.province} {formValues.myPostalCode}</p>
                {finalIsBusinessAddress!=null&& <p style={{marginTop: 0}}>{intl.formatMessage({id: "app.isBusinessAddress"})}: {finalIsBusinessAddress? intl.formatMessage({id: "app.yes"}): intl.formatMessage({id: "app.no"})}</p>}
              </div>
              {formValues && geocodedAddressInfo && !loadingModifyAddress? 
              <div style={{height:'200px', width: '300px', alignItems: 'center'}}>
                {/* {dialogRef?.current?.offsetWidth? dialogRef.current.offsetWidth: 'no'} */}
                <MapComponent 
                  mapHeight={150} 
                  mapWidth={dialogRef?.current?.offsetWidth * 0.75 - 2 * 12} 
                  recipientAddress={formValues} 
                  geocodedCoordinates={geocodedAddressInfo}
                  themeMode={themeMode}
                />
              </div>: <Loading/>}
            </>
            
          }
          onClose={() => {
            setReviewModifyAddressVisible(false)
          }}
          actions={
            [
              [
                {
                  key: 'back',
                  text: <span className="cancelButton">{intl.formatMessage({id: "app.back"})}</span>,
                  onClick: () => {
                    setReviewModifyAddressVisible(false); 
                    setModifyAddressFormVisible(true); 
                    setFormValues(null); 
                    setGeocodedAddressInfo(null)},
                },
                {
                  key: 'confirm',
                  text: intl.formatMessage({id: "app.confirm"}),
                  onClick: () => handleModifyAddress()
                }
              ]
            ]
          }
        />
        </div>
        }
      </>
    )
  }

  const handleModifyAddress = () => {
    setLoadingModifyAddress(true)
    
    let finalBody = {
      street: formValues.street,
      city: formValues.city,
      country: formValues.country,
      province: formValues.province,
      postalCode: formValues.myPostalCode,
      postalCodeToConfirm: postalCode,
      isBusinessAddress: finalIsBusinessAddress
    }
    if (formValues.unit != null && formValues.unit != '') {
      finalBody.unit = formValues.unit
    }

    // if (formValues.isBusinessAddress != null && formValues.isBusinessAddress != '') {
    //   finalBody.isBusinessAddress = formValues.isBusinessAddress
    // }
    // else {
    //   finalBody.isBusinessAddress = false
    // }

    if (geocodedAddressInfo.lat != null) {
      finalBody.lat = geocodedAddressInfo.lat
    }
    if (geocodedAddressInfo.lng != null) {
      finalBody.lng = geocodedAddressInfo.lng
    }

    // alert(JSON.stringify(finalBody))
    // return
    modifyAddress(orderInfo.tracking.id, finalBody)
      .then(res => {
        Toast.show(intl.formatMessage({id: 'app.addressModified'}), 2)
        let newOrderInfo = {
          ...orderInfo,
          tracking: {
            ...orderInfo.tracking,
            destinationLocation: geocodedAddressInfo
          }
        }
        let newRecipientAddress = {
          number: formValues.number,
          country: finalBody.street,
          province: finalBody.province,
          city: finalBody.city,
          street: finalBody.street,
          postalCode: finalBody.postalCode,
          location: geocodedAddressInfo,
          isBusinessAddress: finalBody.isBusinessAddress
        }
        handleOrderInfoUpdate(newOrderInfo)
        handleRecipientAddressUpdate(newRecipientAddress)
        handlePostalCodeUpdate(finalBody.postalCode)
        setLoadingModifyAddress(false)
        setReviewModifyAddressVisible(false)
      })
      .catch(error => {
        // alert(JSON.stringify(error))
        Toast.show(intl.formatMessage({id: 'app.addressCouldNotBeModified'}), 2)
        setLoadingModifyAddress(false)
        setReviewModifyAddressVisible(false)
      })
  }

  const addDeliveryInstructionsContent = () => {
    return (
      <>
        <Dialog
          visible={addDeliveryInstructionsFormVisible}
          title={intl.formatMessage({id: "app.addNotes"})}
          content={
            <>
              <Form
                form={form}
                footer={
                  <>
                    <Button block type='submit' color='primary'>
                      {intl.formatMessage({id:"app.confirm"})}
                    </Button>
                    <Button block style={{marginTop: 5}} onClick={() => {setAddDeliveryInstructionsFormVisible(false)}}>
                      {intl.formatMessage({id:"app.cancel"})}
                    </Button>
                  </>
                }
                onFinish={values => handleSubmitDeliveryInstructions(values)}
              >
                <Form.Item
                  name='notes'
                  label="Notes"
                  rules={[{ required: true, message: intl.formatMessage({id: "app.required"}) }]}
                  // initialValue={orderInfo.tracking.notes}
                >
                  <TextArea 
                    onChange={console.log} 
                    placeholder='Notes' 
                    // defaultValue={orderInfo.tracking.notes}
                  />
                </Form.Item>
              </Form>
              {loadingModifyOrder && <Loading/>}
            </>
          }
          onClose={() => {setAddDeliveryInstructionsFormVisible(false)}}
        />
      </>
    )
  }

  const handleSubmitDeliveryInstructions = (values) => {
    let appendedString = `<Recipient Note>: ${values.notes}`
    let finalNotes = ''

    if (orderInfo.tracking.notes == null || orderInfo.tracking.notes === '') {
      finalNotes = appendedString
    }
    else {
      if (orderInfo.tracking.notes.includes('<Recipient Note>: ')) {
        let firstPart = orderInfo.tracking.notes.split('<Recipient Note>: ')[0]
        if (firstPart.slice(-1) === ' ' || firstPart === '') {
          finalNotes = firstPart + appendedString
        }
        else {
          finalNotes = firstPart + ' ' + appendedString
        }
      }
      else {
        finalNotes = orderInfo.tracking.notes + ' ' + appendedString
      }
    }
    
    // alert(JSON.stringify(values))
    setAddDeliveryInstructionsFormVisible(false)

    setLoadingModifyOrder(true)
    let finalBody = {
      postalCodeToConfirm: postalCode,
      notes: finalNotes
    }
    // alert(JSON.stringify(finalBody))
    // setLoadingModifyOrder(false)
    // return
    modifyOrder(orderInfo.tracking.id, finalBody)
      .then(res => {
        Toast.show(intl.formatMessage({id: 'app.notesModified'}), 2)
        let newOrderInfo = {
          ...orderInfo,
          tracking: {
            ...orderInfo.tracking,
            notes: finalBody.notes
          }
        }
        handleOrderInfoUpdate(newOrderInfo)
        setLoadingModifyOrder(false)
        setAddDeliveryInstructionsFormVisible(false)
      })
      .catch(error => {
        // alert(JSON.stringify(error.message))
        Toast.show(intl.formatMessage({id: 'app.notesCouldNotBeModified'}), 2)
        setLoadingModifyOrder(false)
        setReviewModifyAddressVisible(false)
      })
  }
  return (
      <div className="orderInfoContainer">
        <MapComponent recipientAddress={recipientAddress} orderInfo={orderInfo} mapHeight={windowHeight * 0.55} themeMode={themeMode}/>
        <FloatingPanel anchors={anchors} style={{backgroundColor: themeMode ? "#000d1a": "", zIndex:1000}}>
          
          {renderSwitch()}
          {/* {orderInfo?.tracking?.status && orderInfo.tracking.status != "DELIVERY_COMPLETED"&& orderInfo.tracking.status != "DELIVERY_FAILED"&& orderInfo.tracking.status != "ACTIVE"&&
          <div className="buttonContentBodyContainer">
            <Button
              block
              onClick={() => setActionSheetVisible(true)}
            >
              <EditSOutline/> <FormattedMessage id="app.modifyMyDeliveryButton"/>
            </Button>
          </div>} */}
          {footer()}

          <ActionSheet
            visible={actionSheetVisisble}
            actions={actions}
            onClose={() => setActionSheetVisible(false)}
            extra={intl.formatMessage({id: "app.delightful"})}
            cancelText={intl.formatMessage({id: "app.cancel"})}
            onAction={action => {
              if (action.key === 'rescheduleDelivery') {
                setDatePickerVisible(true)
              }
              if (action.key === 'modifyAddress') {
                setModifyAddressFormVisible(true)
              }
              if (action.key === 'addDeliveryInstructions') {
                setAddDeliveryInstructionsFormVisible(true)
              }
              setActionSheetVisible(false)
            }}
          />
        </FloatingPanel>
        {modifyAddressContent()}
        {rescheduleContent()}
        {addDeliveryInstructionsContent()}
      </div>
  );
}
export default DetailedView
