import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;
// const ORDERS_LIST_SIZE = process.env.REACT_APP_ORDERS_LIST_SIZE;

export function getTrackingInfo(trackingNo) {

  return axios.get(`${BASE_URL}/${trackingNo}`);
}

export function confirmPostalCode(trackingNo, postalCode) {

  return axios.post(
    BASE_URL + '/' + trackingNo +'/actions/confirmPostalCode', 
    {postalCode: postalCode},
    {headers:{'Content-Type': 'application/json'}}
  )
}

export function reschedule(trackingNo, body) {
  // alert(BASE_URL + trackingNo +'/actions/reschedule')
  return axios.patch(
    BASE_URL + '/' + trackingNo +'/actions/reschedule', 
    body,
    {headers:{'Content-Type': 'application/json'}}
  )
}

export function modifyAddress(trackingNo, body) {
  // alert(BASE_URL + trackingNo +'/actions/reschedule')
  return axios.patch(
    BASE_URL + '/' + trackingNo +'/actions/modifyAddress', 
    body,
    {headers:{'Content-Type': 'application/json'}}
  )
}

export function modifyOrder(trackingNo, body) {
  // alert(BASE_URL + trackingNo +'/actions/reschedule')
  return axios.patch(
    BASE_URL + '/' + trackingNo +'/actions/modifyOrder', 
    body,
    {headers:{'Content-Type': 'application/json'}}
  )
}

export function getWindows(body) {
  return axios.post(
    "https://api.boxknight.com/v1/tracking/patchwindow", 
    body,
    {headers:{'Content-Type': 'application/json'}}
  )
}

// const request = options => {
//   let headers = {
//     "Content-Type": "application/json"
//   };

//   addHeaderIfLocal(headers);

//   if (options.queryParams) {
//     options.url +=
//       (options.url.indexOf("?") === -1 ? "?" : "&") +
//       queryParams(options.queryParams);
//     delete options.queryParams;
//   }

//   if (localStorage.getItem("token")) {
//     headers.Authorization = localStorage.getItem("token");
//   }
//   const defaults = { headers: headers };
//   options = Object.assign({}, defaults, options);
//   return fetch(options.url, options)
//     .then(response =>
//       response.json().then(json => {
//         if (!response.ok) {
//           return Promise.reject(json);
//         }
//         return json;
//       })
//     );
// };

// const fileRequest = options => {
//   let headers = {
//     "Content-Type": "application/json"
//   };

//   addHeaderIfLocal(headers);

//   if (options.queryParams) {
//     options.url +=
//       (options.url.indexOf("?") === -1 ? "?" : "&") +
//       queryParams(options.queryParams);
//     delete options.queryParams;
//   }

//   if (localStorage.getItem("token")) {
//     headers.Authorization = localStorage.getItem("token");
//   }
//   const defaults = { headers: headers };
//   options = Object.assign({}, defaults, options);
//   return fetch(options.url, options)
//     .then(response => {
//       if (!response.ok) {
//         return Promise.reject(response.arrayBuffer());
//       }
//       return response.arrayBuffer();
//     }
//     );
// };

// const queryParams = params => {
//   return Object.keys(params)
//     .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
//     .join("&");
// };

// export function getAllOrders(qs) {
//   return request({
//     url: `${BASE_URL}/orders?${qs}`,
//     method: "GET"
//   });
// }

// export function getFirstScanEvents(qs) {
//   return request({
//     url: `${BASE_URL}/orders/${qs}/firstScanEvents`,
//     method: "GET"
//   });
// }

// export function getAddressBook(freeSearch) {
//   return request({
//     url: `${BASE_URL}/orders/addresses?freeSearch=${freeSearch}`,
//     method: "GET"
//   });
// }

// export function getRates(ratesData) {
//   return request({
//     url: `${BASE_URL}/rates`,
//     method: "POST",
//     body: JSON.stringify(ratesData)
//   });
// }

// export function getWindows(originPostalCode, postalCode) {
//   let queryString = "";
//   if (originPostalCode !== undefined){
//     queryString += "originPostalCode="+originPostalCode
//   }
//   if (postalCode !== undefined){
//     if (queryString !== ""){
//       queryString += "&"
//     }
//     queryString += "postalCode="+postalCode
//   }
//   if (queryString !== ""){
//     queryString = "?"+queryString
//   }
//   return request({
//     url: `${BASE_URL}/rates/windows${queryString}`,
//     method: "GET",
//   });
// }

// export function getWixCode() {
//   let token = localStorage.getItem("token");
//   return request({
//     url: `${BASE_URL}/wix?token=${token}`,
//     method: "GET"
//   });
// }

// export function getMerchant() {
//   return request({
//     url: `${BASE_URL}/merchants`,
//     method: "GET"
//   });
// }

// export function createOrder(orderCreateDTO) {
//   return request({
//     url: `${BASE_URL}/orders`,
//     method: "POST",
//     body: JSON.stringify(orderCreateDTO)
//   });
// }

// export function getOrderById(orderId) {
//   return request({
//     url: `${BASE_URL}/orders/${orderId}`,
//     method: "GET"
//   });
// }

// export function getLabelByOrderById(orderId, format) {
//   if (format === undefined) {
//     format = "zpl";
//   }
//   console.log("call")
//   return fileRequest({
//     url: `${BASE_URL}/labels/${orderId}?format=${format}`,
//     method: "GET"
//   });
// }

// export function getPackingSlipByOrderId(orderId, format) {
//   if (format === undefined) {
//     format = "zpl";
//   }
//   return fileRequest({
//     url: `${BASE_URL}/labels/packingslip/${orderId}?format=${format}`,
//     method: "GET"
//   });
// }

// export function deleteOrder(orderId) {
//   return request({
//     url: `${BASE_URL}/orders/${orderId}`,
//     method: "DELETE"
//   });
// }

// export function getLocationsForShopifyOrder(shop, id) {
//   return request({
//     url: `https://platform.boxknight.com/orders/locations?id=${id}&shop=${shop}`,
//     method: "GET"
//   })
// }

// export function fulfillShopifyOrder(shop, fulfillPayload) {
//   return request({
//     url: `https://platform.boxknight.com/orders/fulfill?shop=${shop}`,
//     method: "POST",
//     body: JSON.stringify(fulfillPayload)
//   })
// }

// export function createAndFulfillShopifyOrder(shop, createAndFulfillPayload) {
//   return request({
//     url: `https://platform.boxknight.com/orders/create_and_fulfill?shop=${shop}`,
//     method: "POST",
//     body: JSON.stringify(createAndFulfillPayload)
//   })
// }

// export function pickupReadyOrder(orderId) {
//   let token = localStorage.getItem("token")

//   let headers = {
//     "Content-Type": "application/json",
//     "Authorization": token
//   }

//   addHeaderIfLocal(headers);

//   return axios.patch(`${BASE_URL}/orders/${orderId}/pickupready`, {}, {
//     headers
//   });
// }

// export function notPickupReadyOrder(orderId) {
//   let token = localStorage.getItem("token")

//   let headers = {
//     "Content-Type": "application/json",
//     "Authorization": token
//   }

//   addHeaderIfLocal(headers);

//   return axios.patch(`${BASE_URL}/orders/${orderId}/notPickupready`, {}, {
//     headers
//   });
// }

// export function getLabelFromUrl(labelUrl) {
//   return fetch(labelUrl, { method: "GET" }).then(response =>
//     response.text().then(text => {
//       if (!response.ok) {
//         return Promise.reject(text);
//       }
//       return text;
//     })
//   );
// }

// export function updateOrder(orderId, orderDTO) {
//   let token = localStorage.getItem("token")
//   let headers = {
//     "Content-Type": "application/json",
//     "Authorization": token
//   }
//   addHeaderIfLocal(headers);
//   return axios.patch(`${BASE_URL}/orders/${orderId}/updateOrder`, orderDTO, {
//     headers
//   });
// }

// export function createServiceUser(orderId) {
//   let token = localStorage.getItem("token")
//   let headers = {
//     "Content-Type": "application/json",
//     "Authorization": token
//   }
//   addHeaderIfLocal(headers);
//   return request({
//     url: `${BASE_URL}/users/service`,
//     method: "POST"
//   });
// }

// export function getServiceUsers() {
//   return request({
//     url: `${BASE_URL}/users/service`,
//     method: "GET"
//   });
// }

// export function getSavedAddressBook() {
//   return request({
//     url: `${BASE_URL}/orders/addresses`,
//     method: "GET"
//   });
// }

// export function deleteAddress(id) {
//   return request({
//     url: `${BASE_URL}/orders/addresses/${id}`,
//     method: "DELETE"
//   });
// }

// export function createException(createExceptionDTO) {
//   return request({
//     url: `${BASE_URL}/merchants/deliveryConstraintsByWeek/exceptions`,
//     method: "POST",
//     body: JSON.stringify(createExceptionDTO)
//   });
// }

// export function deleteException(date) {
//   return request({
//     url: `${BASE_URL}/merchants/deliveryConstraintsByWeek/exceptions?date=${date}`,
//     method: "DELETE",
//   })
// }

// export function createUser(createUserDTO) {
//   return request2({
//     url: `${BASE_URL}/users/account`,
//     method: "POST",
//     body: JSON.stringify(createUserDTO)
//   });
// }

// const request2 = options => {
//   let headers = {
//     "Content-Type": "application/json"
//   };

//   addHeaderIfLocal(headers);

//   if (options.queryParams) {
//     options.url +=
//       (options.url.indexOf("?") === -1 ? "?" : "&") +
//       queryParams(options.queryParams);
//     delete options.queryParams;
//   }

//   if (localStorage.getItem("token")) {
//     headers.Authorization = localStorage.getItem("token");
//   }
//   const defaults = { headers: headers };
//   options = Object.assign({}, defaults, options);
//   return fetch(options.url, options);

//     // .then(response => {
//     //   if (!response.ok) {
//     //     return Promise.reject('error');
//     //   }
//     //   else { return response; }
//     // });
// };

// export function getShipstation() {
//   return request({
//     url: `${BASE_URL}/merchants/integrations/shipstation`,
//     method: "GET"
//   });
// }

// export function addShipstation(addShipstationDTO) {
//   return request({
//     url: `${BASE_URL}/merchants/integrations/shipstation`,
//     method: "POST",
//     body: JSON.stringify(addShipstationDTO)
//   });
// }

// export function removeShipstation() {
//   return request({
//     url: `${BASE_URL}/merchants/integrations/shipstation`,
//     method: "DELETE"
//   });
// }

// export function addWebhook(addWebhookDTO) {
//   return request({
//     url: `${BASE_URL}/merchants/integrations/webhooks`,
//     method: "POST",
//     body: JSON.stringify(addWebhookDTO)
//   });
// }

// export function removeWebhook(webhookId) {
//   return request({
//     url: `${BASE_URL}/merchants/integrations/webhooks/${webhookId}`,
//     method: "DELETE"
//   });
// }

// export function addMerchantTag(tagArray) {
//   return request({
//     url: `${BASE_URL}/merchants/updateMerchant/addOrderTags`,
//     method: "PATCH",
//     body: JSON.stringify(tagArray)
//   });
// }
// export function removeMerchantTag(tagArray) {
//   return request({
//     url: `${BASE_URL}/merchants/updateMerchant/removeOrderTags`,
//     method: "PATCH",
//     body: JSON.stringify(tagArray)
//   });
// }

// export function addMerchantEmailNotifications(emailNotifications) {
//   return request({
//     url: `${BASE_URL}/merchants/updateMerchant/addEmailNotifications`,
//     method: "PATCH",
//     body: JSON.stringify(emailNotifications)
//   });
// }

// export function addMerchantRetailerEmailNotifications(emailNotifications) {
//   return request({
//     url: `${BASE_URL}/merchants/updateMerchant/addRetailerEmailNotifications`,
//     method: "PATCH",
//     body: JSON.stringify(emailNotifications)
//   });
// }

// export function removeMerchantEmailNotifications() {
//   return request({
//     url: `${BASE_URL}/merchants/updateMerchant/removeEmailNotifications`,
//     method: "PATCH"
//   });
// }

// export function addMerchantEmail(email) {
//   return request({
//     url: `${BASE_URL}/merchants/updateMerchant/addEmail`,
//     method: "PATCH",
//     body: JSON.stringify(email)
//   });
// }

// export function removeMerchantEmail() {
//   return request({
//     url: `${BASE_URL}/merchants/updateMerchant/removeEmail`,
//     method: "PATCH"
//   });
// }

// // used for testing locally
// const addHeaderIfLocal = headers => {
//   if (BASE_URL.includes('localhost') && process.env.REACT_APP_LOCAL_MERCHANT_ID) {
//     headers["x-aws-sqsd-attr-merchant-id"] = process.env.REACT_APP_LOCAL_MERCHANT_ID
//   }
// }
