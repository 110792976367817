import React, { Component, useState } from "react";
import { Row, Steps, Button, Col, Form, Spin, notification } from "antd";
import Wizard from "./Wizard";
// import { Link, Redirect } from "react-router-dom";
// import { FormattedMessage, injectIntl  } from 'react-intl';
// import  EnterTracking  from "./EnterTracking";
import OrderInfo from "./OrderInfo";
// import Attempts from "./Attempts";

const NewTracking = () => {
    const [trackingNo, setTrackingNo] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [toOrder, setToOrder] = useState(false)
    const [merchant, setMerchant] = useState(null)

    const onSubmit = async values => {
    // alert('hello')
    };

    return (
      <div>
        <Spin spinning={isLoading}>
          <Wizard onSubmit={onSubmit} initialValues={{selectedAttempt:0}}>
            {/* <EnterTracking />
            <OrderInfo /> */}
          </Wizard>
        </Spin>
      </div>
    )
}

export default NewTracking;