import React, { Component, useState, useEffect, useRef } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow, Polyline } from "google-maps-react";
import { GOOGLE_MAP_STYLES, GOOGLE_MAP_STYLES_DARK } from "../util/Helpers";
import PulseDot from 'react-pulse-dot'
import { CarFilled, PropertySafetyFilled } from '@ant-design/icons'
import { FormattedMessage, useIntl } from 'react-intl';
const mapStyles = (width = null, height = null) => {
  if (height != null) {
    return {
      width: width + 'px',
      height: height + "px",
      // width: '70%',
      // height: '50%',
      position: 'relative',
      // justifyContent: 'center'
      // margin: '0 auto'
    };
  }
  // else {
  //   return {
  //     width: "100%"
  //   };
  // }

}

const styles = GOOGLE_MAP_STYLES_DARK;

const MapComponent = (props) => {
  const intl = useIntl()
  const [markers, setMarkers] = useState([])
  const [directionsLines, setDirectionsLines] = useState([])
  const [vehicleAngle, setVehicleAngle] = useState([])
  const [showingInfoWindow, setShowingInfoWindow] = useState(false)
  const [activeMarker, setActiveMarker] = useState({})
  const [activeMarkerPosition, setActiveMarkerPosition] = useState(null)
  const [selectedPlace, setSelectedPlace] = useState(null)
  const [bounds, setBounds] = useState(null)
  const [map, setMap] = useState(null)

  const ranOnce = useRef(false)

  useEffect(() => {
    console.log("Props Updates")
    if (map != null) {
      console.log(props.themeMode)
      if (props.themeMode) {
        map.setOptions({
          styles: GOOGLE_MAP_STYLES_DARK
        });
      } else {
        map.setOptions({
          styles: GOOGLE_MAP_STYLES
        });
      }
    }
    let myMarkers = []
    if (props.orderInfo) {
      myMarkers = [
        {
          id: "destination",
          name: "Destination",
          address: props.recipientAddress.number + ' ' + props.recipientAddress.street,
          location: props.recipientAddress.location// props.orderInfo.tracking.destinationLocation
        }
      ]

      if (props.orderInfo.tracking.worker && props.orderInfo.tracking.status === 'ACTIVE' && props.orderInfo.tracking.location) {
        let directionsService = new props.google.maps.DirectionsService();
        console.log(props.orderInfo.tracking.location);
        let start = `${props.orderInfo.tracking.location.lat},${props.orderInfo.tracking.location.lng}`;
        let end = `${props.recipientAddress.location.lat},${props.recipientAddress.location.lng}`;
        console.log(start)
        console.log(end)
        directionsService.route({
          origin: start,
          destination: end,
          travelMode: props.google.maps.TravelMode.DRIVING
        },
          (result, status) => {
            console.log(status);
            if (status === props.google.maps.DirectionsStatus.OK) {
              const overViewCoords = result.routes[0].overview_path;
              const point1 = overViewCoords[0];
              const point2 = overViewCoords[1];
              const angle = props.google.maps.geometry.spherical.computeHeading(point1, point2)
              const adjustedAngle = angle - 90;
              console.log(adjustedAngle)
              setVehicleAngle(adjustedAngle)
              setDirectionsLines(overViewCoords)
            } else {
              console.warn(`error fetching directions ${status}`);
            }
          }
        )
        myMarkers.push({
          id: 'driver',
          name: 'Driver',
          address: props.orderInfo.tracking.worker.name,
          location: props.orderInfo.tracking.location,
          status: 'car'
        })
      }
    }

    else if (props.geocodedCoordinates) {
      myMarkers.push({
        id: 'destination',
        name: 'Destination',
        address: props.recipientAddress.number + ' ' + props.recipientAddress.street,
        location: props.geocodedCoordinates,
        // status: 'car'
      })
    }
    setMarkers(myMarkers)

    // set bounds only the first time
    if (!ranOnce.current) {
      calculateBounds(myMarkers)
      ranOnce.current = true
    }
  }, [props.orderInfo, props.geocodedCoordinates, props.themeMode])

  const onMapClicked = props => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false)
      setActiveMarker(null)
      setActiveMarkerPosition(null)
    }
  };

  const onMarkerClick = (props, myMarker, e) => {
    setSelectedPlace(props)
    setActiveMarker(myMarker)
    setActiveMarkerPosition(props.position)
    setShowingInfoWindow(true)
  };

  const onMarkerClose = () => {
    setSelectedPlace(null)
    setActiveMarker(null)
    setActiveMarkerPosition(null)
    setShowingInfoWindow(false)
  }

  const calculateBounds = markers => {
    let myBounds = new props.google.maps.LatLngBounds();
    for (let i = 0; i < markers.length; i++) {
      if (markers[i]["location"] != null) {
        myBounds.extend(markers[i]["location"]);
      }
    }
    setBounds(myBounds)
  };

  const displayMarkers = () => {
    let markerComponents = markers.map((marker, index) => {
      return (
        <Marker
          key={index}
          id={marker.id}
          position={marker.location}
          title={marker.name}
          name={marker.address}
          onClick={onMarkerClick}
          icon={
            marker.status === 'car' ?
              {
                url: '/images/icons/car.svg',
                anchor: new props.google.maps.Point(15, 15),
                scaledSize: new props.google.maps.Size(30, 30),
              }
              :
              {
                url: '/images/icons/marker.svg',
                anchor: new props.google.maps.Point(19, 38),
                scaledSize: new props.google.maps.Size(38, 38)
              }
          }
        />
      );
    });
    return markerComponents;
  };

  const CenterControl = (controlDiv, map) => {
    // Set CSS for the control border.
    const controlUI = document.createElement("div");

    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginTop = "8px";
    controlUI.style.textAlign = "center";
    controlUI.title = controlUI.title = intl.formatMessage({ id: "app.clickToRecenter" });
    // controlUI.style.marginTop = 'auto'
    // controlUI.style.float = "right";
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    const controlText = document.createElement("div");

    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "16px";
    controlText.style.height = "22px";
    //controlText.style.lineHeight = "16px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "<img src='/images/aim.svg'>"
    controlUI.appendChild(controlText);
    controlUI.addEventListener("click", () => {
      calculateBounds(markers);
      // map.setCenter(myCenter);
    });
  }
  const loadMap = (mapProps, map) => {
    setMap(map);
    const centerControlDiv = document.createElement("div");
    centerControlDiv.style.marginBottom = "10px";
    centerControlDiv.style.marginRight = "10px";
    // centerControlDiv.style.display = "flex";
    // centerControlDiv.style.marginTop = "auto";
    // centerControlDiv.style.height = "100%";
    // centerControlDiv.style.float = "right";
    CenterControl(centerControlDiv, map)
    map.controls[props.google.maps.ControlPosition.RIGHT_TOP].push(centerControlDiv)
  }

  return (
    <div id="scoped-content">
      <style type="text/css">{`
              img[src^="/images/icons/car.svg"] {
                transform: rotate(${vehicleAngle}deg);
              }
        `}</style>
      <Map
        onClick={onMapClicked}
        google={props.google}
        style={mapStyles(props.mapWidth, props.mapHeight)}
        styles={props.themeMode ? GOOGLE_MAP_STYLES_DARK : GOOGLE_MAP_STYLES}
        zoom={10}
        maxZoom={16}
        bounds={bounds}
        fullscreenControl={false}
        mapTypeControl={false}
        streetViewControl={false}
        zoomControl={false}
        //style={{margin: '0 auto', width: '50%'}}
        onReady={loadMap}
      >
        {displayMarkers()}
        <InfoWindow
          visible={showingInfoWindow}
          //position={activeMarkerPosition}
          marker={activeMarker}
          //options={{ pixelOffset: new props.google.maps.Size(0, -30) }}
          onClose={onMarkerClose}
        >
          {selectedPlace && <div>
            <h4>{selectedPlace.title}</h4>
            {selectedPlace.name}
          </div>}
        </InfoWindow>
        <Polyline
          path={directionsLines}
          strokeColor={props.themeMode ? "#1890FF" : "#000000"}
          strokeOpacity={1.0}
          strokeWeight={2} />
      </Map>
    </div>

  );
}

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.recipientAddress.location.lng === nextProps.recipientAddress.location.lng && prevProps.recipientAddress.location.lat === nextProps.recipientAddress.location.lat && 
    (nextProps.orderInfo.tracking.location == null || prevProps.orderInfo.tracking.location.lng === nextProps.orderInfo.tracking.location.lng && prevProps.orderInfo.tracking.location.lat === nextProps.orderInfo.tracking.location.lat) &&
    prevProps.themeMode === nextProps.themeMode
  )
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyDJpy8oGMRKK6M5OcuNl243Se1M14b1C1s'
})(React.memo(MapComponent, areEqual))